<script>
import api from "@/helpers/api-rest/api.js";
import store from '@/state/store';
import format from 'date-fns/format';
import AccessionCenterModal from "@/components/modals/accession-center-modal.vue";
import AccessionGeneralModal from "@/components/modals/accession-general-modal.vue";
import qs from 'qs';

export default {
  props: {
    essayId: { type: [Number, String] },
    centerId: { type: Number },
  },
  components: { 
    AccessionCenterModal,
    AccessionGeneralModal
  },
  data() {
    return {
      tableData: [],
      title: "Usuarios",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      fields: [
        { 
          key: 'created_at', sortable: false, label: 'Fecha de creación', 
          formatter: (value) => {
            return format(new Date(value), 'dd/MM/yyyy');
          } 
        },
        { key: "name", sortable: false, label: "Centro" },
        { key: "document", sortable: false, label: this.renderDocumentLabel() },
        { key: "status", sortable: false, label: "Estado" },
        { key: "generate_anexoV", sortable: false, label: "Anexo V" },
        { key: "action", tdClass:"custom-table-action",  sortable: false, label: "" }
      ],
      editMode: false,
      accessionCenterModalId: 'ModalAccessionCenter',
      accessionGeneralModalId: 'ModalAccessionGeneral',
      accesionCenterEdit: undefined,
      accesionCenterGeneralEdit: undefined,
      dataEssay: {}
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  methods: {
    // loadDataEssay(){
    //   const apiEndpoint = `/api/v1/essays/${this.essayId}`;
    //   api.get({url:apiEndpoint})
    //     .then(response => {
    //       const data = response?.data?.data;
    //       this.dataEssay = data;
    //       console.log(this.dataEssay.project.project_type_id);
                   
    //     })
    //     .catch(error => {
    //       console.error("Error loading data:", error);
    //     });
    // },
    renderDocumentLabel() {
      return (
        `<span>
          Documento <i v-b-tooltip.hover title="Anexo VI" class="far fa-question-circle font-size-12 ml-2"></i>
        </span>`
      );
    },
    showElement(roleId = [], show = true, showEdit = true) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id) && show && showEdit) {
        return true;
      }
      return false;
    },
    loadDataFromApi(){
      const apiEndpoint = `/api/v1/accession-centers?essay_id=${this.essayId}`;
      api.get({url:apiEndpoint})
        .then(response => {
          this.tableData = response?.data?.data;
          console.log(this.tableData);
          
        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    downloadFile(fileName, folderID){
        const params = {
            'fileName': fileName,
            'folderID': folderID,
        };

        const queryString = qs.stringify(params);
        const baseUrl = process.env.VUE_APP_API_HOST;
        const url = `${baseUrl}/download-drive?${queryString}`;
        window.open(url, '_blank');
    },
    success(accesion_center) {
      console.log(accesion_center);
      this.loadDataFromApi();
    },
    editAccessionCenter(accesion_center) {
      this.accesionCenterEdit = accesion_center;
      this.$bvModal.show(this.accessionCenterModalId);
    },
    addAccessionCenter(){
      this.accesionCenterEdit = {id: null, document_name: null};
      this.$bvModal.show(this.accessionCenterModalId);
    },
    editGenerateAnexoV(accesion_general) {
      accesion_general.typeAccession = 'center';
      this.accesionCenterGeneralEdit = accesion_general;
      this.$bvModal.show(this.accessionGeneralModalId);
    },
    addGenerateAnexoV(){
      this.accesionCenterGeneralEdit = {
        id: null, 
        document_name: null, 
        typeAccession: 'center',
        courses: '',
        students_number: null,
        accession_general_generate: {
          reason: '',
          accession_general_activities: []
        }, 
      };
      this.$bvModal.show(this.accessionGeneralModalId);
    },
  },
  mounted() {
    this.loadDataFromApi();
    // Set the initial number of items
    this.totalRows = this.tableData.length;
  },
}
</script>

<template>
  <div>
    <AccessionCenterModal :id="accessionCenterModalId" :accesion_center="accesionCenterEdit" :essayId="essayId" :centerId="centerId" @success="success"/>
    <AccessionGeneralModal :id="accessionGeneralModalId" :accession_general="accesionCenterGeneralEdit" :essayId="essayId" @success="success"/>
    <button v-if="showElement([3])" @click="addAccessionCenter()" class="btn btn-success mb-4"><i class="fa fa-plus"></i> Añadir centro</button>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-2">
              <div class="col-sm-12 col-md-6">
                <h4 class="card-title">Lista de adhesiones de centros</h4>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                ref="userTable"
              >
                <template v-slot:cell(name)="row">
                  <div>
                    
                    <div v-if="row.item.essay && row.item.essay.project.project_type_id === 1">
                      <!-- Si la condición se cumple, muestra row.center.name -->
                      {{ row.item.center.name }}
                    </div>
                    <!-- Si no se cumple la condición, muestra row.name -->
                    <div v-else>
                      {{ row.item.name }}
                    </div>
                  </div>
                </template>
                <template v-slot:cell(status)="row">
                  <div>
                    <div>
                      <b-badge pill :variant="row.item.status === 'pendiente' ? 'info' : (row.item.status === 'rechazada' ? 'danger' : 'success')">
                        {{ row.item.status === 'pendiente' ? 'Pendiente' : (row.item.status === 'rechazada' ? 'Rechazada' : 'Aceptada') }}
                      </b-badge>
                    </div>
                  </div>
                </template>
                <template v-slot:head(document)="data">
                  <div v-html="data.label"></div>
                </template>
                <template v-slot:cell(document)="row">
                  <div v-if="row.item.document_path">
                    <a href="javascript:void(0);" class="mr-3 text-secondary" @click.prevent="downloadFile(row.item.document_name, row.item.essay.folder_google_id)">
                      <i class="mdi mdi-file-download-outline font-size-18"></i>
                    </a>
                  </div>
                </template>
                <template v-slot:cell(generate_anexoV)="row">
                  <div v-if="row.item.accession_general_generate">
                    <b-button pill size="sm" class="w-75" @click="editGenerateAnexoV(row.item)"><i class="mdi mdi-eye-outline font-size-14"></i> Ver</b-button>
                  </div>
                  <div v-else>
                    <b-button pill size="sm" class="w-75" @click="addGenerateAnexoV(row.item)"><i class="fa fa-plus"></i> Generar</b-button>
                  </div>
                  <!-- <div v-if="row.item.accession_general_generate">
                    <a href="javascript:void(0);" class="mr-3 text-secondary">
                      <i class="mdi mdi-file-download-outline font-size-18"></i>
                    </a>
                  </div>
                  <div v-else>
                    <b-button pill size="sm"><i class="fa fa-plus"></i> Generar</b-button>
                  </div> -->
                </template>
                <template v-slot:cell(action)="row">
                  <div>
                    <a href="javascript:void(0);" class="mr-3 text-secondary" title="Edit" @click="editAccessionCenter(row.item)">
                      <i class="mdi mdi-square-edit-outline font-size-18"></i>
                    </a>
                  </div>
                </template>
                <template v-slot:empty>
                  <div class="text-center">No hay registros</div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>